import React, { ReactElement } from "react";

export default function Logo(): ReactElement {
    return (
        <svg
            width="273"
            height="33"
            viewBox="0 0 273 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_72_9139)">
                <path
                    d="M69.8799 32.8122V11.4043H78.6581C82.1357 11.4043 84.6941 12.6071 86.3332 15.0128C86.8569 15.7708 87.1933 16.6423 87.3148 17.5556C87.4617 19.0684 87.521 20.5885 87.4923 22.1083C87.5209 23.6311 87.4617 25.1543 87.3148 26.6703C87.1929 27.5806 86.8564 28.449 86.3332 29.2037C84.6878 31.6156 82.1295 32.8185 78.6581 32.8122H69.8799ZM75.6946 28.138H78.2561C78.7868 28.1842 79.3207 28.0983 79.8101 27.8879C80.2995 27.6774 80.7291 27.349 81.0606 26.9321C81.3401 26.5298 81.5137 26.0636 81.5654 25.5765C81.6769 24.4271 81.7206 23.2722 81.6963 22.1176C81.7204 20.9631 81.6767 19.8081 81.5654 18.6587C81.5117 18.1721 81.3383 17.7064 81.0606 17.3032C80.727 16.8891 80.2968 16.5635 79.8077 16.3548C79.3187 16.1462 78.7858 16.0609 78.2561 16.1066H75.6946V28.138Z"
                    fill="#115C7B"
                />
                <path
                    d="M98.2236 32.9995C92.5585 32.9995 89.7228 30.2418 89.7165 24.7262C89.6528 23.6158 89.8165 22.5042 90.1975 21.4594C90.5785 20.4145 91.1687 19.4584 91.9321 18.6497C93.5557 17.2358 95.636 16.457 97.7889 16.457C99.9419 16.457 102.022 17.2358 103.646 18.6497C104.355 19.3965 104.908 20.2772 105.272 21.2403C105.636 22.2035 105.805 23.2297 105.768 24.2587V26.306H95.0732C95.0714 26.6476 95.1461 26.9852 95.2917 27.2941C95.4372 27.6031 95.6501 27.8755 95.9146 28.0916C96.5671 28.6137 97.3893 28.8767 98.2236 28.8301C98.9416 28.8589 99.6586 28.7511 100.336 28.5123C100.922 28.2655 101.457 27.9138 101.916 27.4746L105.151 30.4381C104.271 31.2646 103.248 31.9235 102.131 32.3825C100.878 32.8275 99.5531 33.0367 98.2236 32.9995ZM95.0732 23.0434H100.383C100.391 22.6043 100.288 22.1703 100.084 21.7814C99.8281 21.3957 99.4808 21.0794 99.0731 20.8605C98.6653 20.6417 98.2097 20.5271 97.7468 20.5271C97.284 20.5271 96.8285 20.6417 96.4207 20.8605C96.0129 21.0794 95.6656 21.3957 95.4097 21.7814C95.1927 22.1669 95.0769 22.6011 95.0732 23.0434V23.0434Z"
                    fill="#115C7B"
                />
                <path
                    d="M117.837 32.9992C116.324 33.0531 114.849 32.5244 113.714 31.5221V32.8403H108.433V11.4043H113.883V17.808C114.99 16.8676 116.414 16.3831 117.865 16.4525C119.434 16.3632 120.975 16.8937 122.156 17.9295C122.689 18.4491 123.087 19.0909 123.315 19.7992C123.562 20.4932 123.719 21.2157 123.783 21.9493C123.83 22.5944 123.848 23.5105 123.848 24.6884C123.848 25.8663 123.848 26.7825 123.783 27.4275C123.719 28.1611 123.562 28.8836 123.315 29.5777C123.089 30.2869 122.691 30.9292 122.156 31.4474C120.976 32.5119 119.425 33.0694 117.837 32.9992V32.9992ZM114.313 27.6425C114.527 27.9236 114.804 28.1513 115.121 28.3081C115.438 28.4648 115.787 28.5464 116.14 28.5464C116.494 28.5464 116.843 28.4648 117.16 28.3081C117.477 28.1513 117.753 27.9236 117.968 27.6425C118.312 26.7106 118.458 25.7174 118.398 24.7258C118.458 23.7343 118.312 22.7411 117.968 21.8091C117.753 21.5281 117.477 21.3003 117.16 21.1436C116.843 20.9868 116.494 20.9053 116.14 20.9053C115.787 20.9053 115.438 20.9868 115.121 21.1436C114.804 21.3003 114.527 21.5281 114.313 21.8091C113.969 22.7411 113.823 23.7343 113.883 24.7258C113.823 25.7174 113.969 26.7106 114.313 27.6425V27.6425Z"
                    fill="#115C7B"
                />
                <path
                    d="M135.346 32.8125H132.626C131.871 32.8699 131.112 32.7717 130.397 32.5241C129.681 32.2764 129.024 31.8846 128.466 31.3728C128.024 30.9369 127.673 30.4173 127.434 29.8443C127.194 29.2713 127.072 28.6563 127.073 28.0354V21.0615H125.446V17.266H127.073V12.5918H132.523V17.266H135.328V21.0615H132.523V27.6708C132.523 28.3346 132.888 28.6711 133.617 28.6711H135.346V32.8125Z"
                    fill="#115C7B"
                />
                <path
                    d="M148.705 32.9997C146.305 33.0764 143.966 32.2375 142.161 30.6532C141.617 30.1635 141.148 29.5966 140.769 28.9705C140.394 28.3087 140.147 27.5826 140.039 26.8297C139.909 26.0071 139.824 25.3059 139.787 24.7263C139.787 24.1467 139.74 23.2773 139.74 22.1088C139.74 20.9402 139.74 20.0802 139.787 19.4912C139.834 18.9023 139.909 18.2105 140.039 17.3878C140.145 16.6375 140.393 15.9142 140.769 15.2564C141.145 14.6257 141.615 14.0553 142.161 13.5643C144.022 12.0521 146.346 11.2266 148.743 11.2266C151.14 11.2266 153.464 12.0521 155.324 13.5643C155.873 14.0529 156.343 14.6237 156.717 15.2564C157.092 15.9154 157.342 16.638 157.456 17.3878C157.574 18.0842 157.655 18.7863 157.699 19.4912C157.699 20.0802 157.755 20.9496 157.755 22.1088C157.755 23.268 157.755 24.1467 157.699 24.7263C157.655 25.4313 157.574 26.1334 157.456 26.8297C157.341 27.5821 157.091 28.3075 156.717 28.9705C156.34 29.5986 155.871 30.166 155.324 30.6532C153.502 32.2573 151.131 33.0976 148.705 32.9997ZM148.705 28.3255C149.139 28.361 149.576 28.2951 149.98 28.1328C150.384 27.9706 150.744 27.7165 151.033 27.3906C151.381 26.9443 151.61 26.4167 151.697 25.8575C151.865 24.6186 151.931 23.3679 151.893 22.1181C151.93 20.8684 151.864 19.6178 151.697 18.3787C151.611 17.8134 151.383 17.2792 151.033 16.8269C150.406 16.2214 149.568 15.8831 148.696 15.8831C147.824 15.8831 146.986 16.2214 146.359 16.8269C146.013 17.2814 145.785 17.8146 145.695 18.3787C145.528 19.6178 145.462 20.8684 145.499 22.1181C145.461 23.3679 145.527 24.6186 145.695 25.8575C145.787 26.4154 146.015 26.9421 146.359 27.3906C146.652 27.7157 147.016 27.9679 147.424 28.127C147.831 28.286 148.27 28.3476 148.705 28.3068V28.3255Z"
                    fill="#115C7B"
                />
                <path
                    d="M161.308 32.8126V21.0616H159.672V17.2661H161.308V15.8545C161.307 15.2336 161.429 14.6187 161.668 14.0457C161.908 13.4726 162.259 12.953 162.701 12.5171C163.256 12.0035 163.911 11.6097 164.625 11.3604C165.339 11.1111 166.097 11.0116 166.851 11.0681H169.74V15.2188H167.87C167.141 15.2188 166.777 15.5554 166.777 16.2191V17.2661H169.759V21.0616H166.758V32.8126H161.308Z"
                    fill="#115C7B"
                />
                <path
                    d="M172.535 32.8129V21.0619H170.908V17.2664H172.535V15.8548C172.534 15.2339 172.656 14.6189 172.895 14.0459C173.135 13.4729 173.486 12.9533 173.928 12.5174C174.483 12.0068 175.137 11.6149 175.849 11.3657C176.561 11.1166 177.317 11.0154 178.069 11.0684H180.958V15.2191H179.088C178.359 15.2191 177.994 15.5556 177.994 16.2194V17.2664H180.986V21.0619H177.994V32.8129H172.535Z"
                    fill="#115C7B"
                />
                <path
                    d="M190.418 32.9995C184.747 32.9995 181.911 30.2417 181.911 24.7262C181.846 23.6165 182.009 22.5053 182.388 21.4604C182.767 20.4156 183.356 19.4592 184.118 18.6497C185.741 17.2358 187.822 16.457 189.974 16.457C192.127 16.457 194.208 17.2358 195.831 18.6497C196.543 19.3948 197.099 20.275 197.465 21.2384C197.831 22.2018 198 23.2288 197.963 24.2587V26.306H187.259C187.257 26.6485 187.332 26.9871 187.48 27.2963C187.627 27.6055 187.842 27.8774 188.109 28.0916C188.76 28.6169 189.584 28.8803 190.418 28.8301C191.133 28.8593 191.847 28.7514 192.522 28.5123C193.107 28.2655 193.643 27.9138 194.102 27.4746L197.364 30.4194C196.48 31.2442 195.454 31.9029 194.335 32.3638C193.081 32.8176 191.752 33.0332 190.418 32.9995V32.9995ZM187.259 23.0434H192.578C192.581 22.6048 192.479 22.1719 192.279 21.7814C192.025 21.3858 191.676 21.0603 191.263 20.8349C190.851 20.6095 190.388 20.4913 189.918 20.4913C189.448 20.4913 188.986 20.6095 188.573 20.8349C188.161 21.0603 187.812 21.3858 187.558 21.7814C187.358 22.1719 187.256 22.6048 187.259 23.0434Z"
                    fill="#115C7B"
                />
                <path
                    d="M200.617 32.8128V16.64H205.936V17.9581C207.121 16.9826 208.608 16.4492 210.143 16.4492C211.678 16.4492 213.165 16.9826 214.35 17.9581L210.227 21.7536C209.705 21.23 209.003 20.9257 208.264 20.9029C207.73 20.9155 207.214 21.0953 206.787 21.417C206.547 21.6243 206.361 21.8862 206.244 22.1808C206.127 22.4753 206.082 22.7937 206.114 23.1091V32.8128H200.617Z"
                    fill="#115C7B"
                />
                <path
                    d="M221.456 32.9994C220.343 32.9994 219.436 32.9994 218.726 32.8966C217.875 32.7868 217.042 32.5668 216.249 32.2422C215.3 31.8777 214.428 31.3387 213.678 30.653L217.202 27.4651C218.434 28.4453 219.988 28.9289 221.558 28.8207C222.091 28.822 222.621 28.74 223.129 28.5776C223.634 28.4187 223.877 28.1476 223.877 27.7643C223.877 27.166 223.447 26.8295 222.587 26.7453L219.829 26.5023C216.37 26.2031 214.641 24.6326 214.641 21.828C214.616 21.0442 214.798 20.2676 215.169 19.5766C215.54 18.8856 216.086 18.3046 216.753 17.8923C218.238 16.9234 219.982 16.4279 221.755 16.4714C224.989 16.4714 227.336 17.1351 228.794 18.4626L225.438 21.5008C224.737 20.8558 223.466 20.566 221.652 20.566C221.189 20.5301 220.727 20.6343 220.324 20.8651C220.207 20.926 220.106 21.015 220.031 21.1243C219.956 21.2336 219.909 21.3597 219.894 21.4915C219.894 22.015 220.278 22.3142 221.054 22.4263L223.811 22.6694C225.298 22.7051 226.726 23.2571 227.85 24.2306C228.345 24.7498 228.721 25.3714 228.949 26.0514C229.178 26.7314 229.256 27.4534 229.175 28.1664C229.095 28.8794 228.86 29.5662 228.485 30.1783C228.111 30.7905 227.607 31.3131 227.009 31.7093C225.316 32.6541 223.391 33.1013 221.456 32.9994V32.9994Z"
                    fill="#115C7B"
                />
                <path
                    d="M230.607 32.8127V29.4473H234.347V32.8127H230.607Z"
                    fill="#115C7B"
                />
                <path
                    d="M240.975 32.9339C240.552 32.9353 240.129 32.8978 239.713 32.8217C239.255 32.7392 238.811 32.5913 238.395 32.3823C237.897 32.1616 237.453 31.8347 237.095 31.4245C236.736 31.0144 236.472 30.5309 236.32 30.0078C235.87 28.5618 235.87 27.0133 236.32 25.5673C236.496 25.0129 236.789 24.5029 237.18 24.0716C237.525 23.7034 237.937 23.4051 238.395 23.1928C238.811 22.9811 239.255 22.83 239.713 22.7441C240.131 22.6791 240.553 22.6478 240.975 22.6506C242.315 22.5978 243.625 23.0556 244.64 23.9313L242.34 26.016C241.979 25.6491 241.49 25.4349 240.975 25.4177C240.591 25.4105 240.217 25.5472 239.928 25.801C239.561 26.3986 239.367 27.0862 239.367 27.7876C239.367 28.4889 239.561 29.1765 239.928 29.7741C240.216 30.0313 240.589 30.1714 240.975 30.1667C241.491 30.1452 241.979 29.9279 242.34 29.5591L244.64 31.6438C243.628 32.5263 242.317 32.9879 240.975 32.9339V32.9339Z"
                    fill="#115C7B"
                />
                <path
                    d="M249.968 32.9346C249.316 32.9657 248.664 32.8677 248.05 32.6464C247.435 32.425 246.871 32.0845 246.388 31.6446C245.953 31.2197 245.632 30.6926 245.453 30.1114C245.252 29.3557 245.164 28.5745 245.191 27.793C245.168 27.0049 245.259 26.2176 245.463 25.4559C245.646 24.8798 245.967 24.357 246.397 23.9321C247.403 23.0999 248.668 22.6445 249.973 22.6445C251.279 22.6445 252.543 23.0999 253.549 23.9321C253.981 24.3553 254.302 24.8787 254.484 25.4559C254.676 26.2161 254.758 27.0001 254.727 27.7836C254.752 28.5645 254.667 29.3449 254.474 30.1021C254.292 30.6815 253.971 31.2077 253.54 31.6352C253.06 32.077 252.497 32.4193 251.884 32.6424C251.271 32.8654 250.62 32.9647 249.968 32.9346V32.9346ZM249.968 30.1301C250.136 30.146 250.304 30.1285 250.465 30.0788C250.625 30.029 250.774 29.948 250.903 29.8403C251.322 29.2312 251.499 28.4879 251.399 27.7556C251.498 27.0174 251.321 26.2686 250.903 25.6522C250.636 25.45 250.303 25.3535 249.968 25.3811C249.634 25.3517 249.301 25.4484 249.034 25.6522C248.61 26.2765 248.44 27.0384 248.557 27.7836C248.454 28.5174 248.635 29.2628 249.062 29.8684C249.313 30.083 249.639 30.1904 249.968 30.1675V30.1301Z"
                    fill="#115C7B"
                />
                <path
                    d="M256.512 32.8122V22.7626H259.821V23.5853C260.529 22.9529 261.453 22.6181 262.401 22.6504C262.902 22.6287 263.401 22.7114 263.868 22.8933C264.335 23.0751 264.758 23.3522 265.112 23.7068C266.001 22.9997 267.109 22.626 268.244 22.6504C269.21 22.5887 270.165 22.8923 270.918 23.5011C271.317 23.866 271.633 24.3133 271.843 24.812C272.052 25.3108 272.151 25.8491 272.133 26.3898V32.8122H268.749V26.8479C268.771 26.6463 268.743 26.4423 268.666 26.2545C268.589 26.0667 268.467 25.9011 268.309 25.7728C268.04 25.571 267.712 25.462 267.375 25.462C267.038 25.462 266.71 25.571 266.44 25.7728C266.284 25.9021 266.164 26.0681 266.088 26.2559C266.013 26.4436 265.986 26.6471 266.01 26.8479V32.8589H262.616V26.8479C262.65 26.6413 262.63 26.4295 262.558 26.2329C262.486 26.0364 262.364 25.8618 262.205 25.7261C261.935 25.5243 261.607 25.4152 261.27 25.4152C260.933 25.4152 260.605 25.5243 260.335 25.7261C260.18 25.8554 260.059 26.0214 259.984 26.2091C259.909 26.3968 259.882 26.6003 259.905 26.8011V32.8122H256.512Z"
                    fill="#115C7B"
                />
                <path
                    d="M6.48782 32.6357V13.9949H0V8.75977H19.4541V13.9949H12.9756V32.6357H6.48782Z"
                    fill="#115C7B"
                />
                <path
                    d="M15.5273 32.6357L25.1282 8.75977H30.0922L39.7118 32.6357H33.3268L31.8871 29.0833H23.5577L22.305 32.6357H15.5273ZM25.3806 24.2221H29.9053L27.6429 17.8558L25.3806 24.2221Z"
                    fill="#115C7B"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M33.374 8.64731L45.3681 20.6414L50.4256 25.6989L55.4831 20.6414L76.1245 0H66.0094L50.4256 15.5839L43.489 8.64731H33.374Z"
                    fill="#51A5D6"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M50.4257 15.584L45.3682 20.6415L50.4257 25.699L55.4832 20.6415L50.4257 15.584Z"
                    fill="#115C7B"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M49.7899 26.3348L44.7324 21.2773L33.374 32.6357H43.489L48.5933 27.5315L49.7899 26.3348Z"
                    fill="#EB7F58"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M56.1561 21.3145L51.0986 26.372L57.3621 32.6354H67.4865L57.3153 22.4737L56.1561 21.3145Z"
                    fill="#EB7F58"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M51.1078 3.01953C51.8289 3.01953 52.5338 3.23336 53.1333 3.63397C53.7329 4.03459 54.2002 4.604 54.4762 5.2702C54.7521 5.9364 54.8243 6.66947 54.6836 7.3767C54.543 8.08394 54.1957 8.73357 53.6858 9.24346C53.176 9.75335 52.5263 10.1006 51.8191 10.2413C51.1119 10.3819 50.3788 10.3097 49.7126 10.0338C49.0464 9.75784 48.477 9.29054 48.0763 8.69098C47.6757 8.09141 47.4619 7.38651 47.4619 6.66543C47.4644 5.69923 47.8493 4.77332 48.5325 4.09012C49.2157 3.40691 50.1416 3.022 51.1078 3.01953Z"
                    fill="#51A5D6"
                />
            </g>
            <defs>
                <clipPath id="clip0_72_9139">
                    <rect width="272.124" height="33" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
